.logo {
    height: 60px;
    max-width: 200px;
    padding: 5px;
    transition: all 0.3s ease-in-out;
    object-fit: contain;
}

.logo:hover {
    transform: scale(1.05);
}

@media screen and (max-width: 768px) {
    .logo {
        height: 50px;
        max-width: 150px;
    }
}