@tailwind utilities;

.container {
    z-index: 1;
    height: 90vh;
    width: 100vh;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 10px;
}

@media screen and (max-width: 1300px) {
    .container {
        width: 95vw;
    }
}

@media screen and (max-height: 640px) {
    .container {
        height: 700px;
    }
}

h1 {
    font-size: 1.8rem;
    color: #5A84A4;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
}

.img-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 20vh;
    overflow: hidden;
}

.small-font {
    font-size: 1.5rem;
}

.img, .img-small {
    width: 60px;
    height: 60px;
    object-fit: contain;
    transition: all 0.3s ease-out;
}

.img:hover, .img-small:hover {
    transform: scale(1.1);
}

.web, .dev, .framework {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.box-c {
    width: 150vh;
}

@media screen and (max-height: 640px) {
    .box-c {
        grid-auto-rows: 200px;
    }
}

.center-box-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.para {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 15px;
}

.skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gitLink {
    display: inline-block;
    padding: 8px 16px;
    margin: 5px;
    background-color: #5A84A4;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: all 0.3s ease;
}

.gitLink:hover {
    background-color: #3a5a7a;
    transform: translateY(-2px);
}

.btnWrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.skill-box {
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.skill-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}