:root {
    --primary-color: #4a7bff;
    --primary-light: #e6f0ff;
    --text-color: #333333;
    --placeholder-color: #999999;
    --error-color: #FF4D4F;
    --success-color: #52C41A;
  }

.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.wrapper {
  background-color: var(--primary-light);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.nav {
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
}

.return-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.return-link:hover {
  color: #3a62cc;
  transform: translateX(-5px);
}

.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 40px 20px;
}

.contact-form {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(74, 123, 255, 0.1);
  padding: 40px;
  width: 100%;
  max-width: 600px;
  transform: translateY(20px);
  opacity: 0;
  animation: fadeInUp 0.6s ease forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

h2 {
  color: var(--primary-color);
  font-size: 32px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 700;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  margin-bottom: 20px;
}

.contact-input-top{
    width: 90%;
    padding: 12px 16px;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    font-size: 16px;
    transition: all 0.3s ease;
    color: var(--text-color);
    background-color: #f9f9f9;

}

input,
textarea {
  width: 95%;
  padding: 12px 16px;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 16px;
  transition: all 0.3s ease;
  color: var(--text-color);
  background-color: #f9f9f9;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(74, 123, 255, 0.1);
  background-color: white;
}

input::placeholder,
textarea::placeholder {
  color: var(--placeholder-color);
}

input:hover,
textarea:hover {
  border-color: var(--primary-color);
}

textarea {
  min-height: 120px;
  resize: vertical;
}

.error-message {
  color: var(--error-color);
  font-size: 14px;
  margin-top: 5px;
  display: block;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
}

.submit-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 12px;
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  margin-top: 10px;
  position: relative;
  overflow: hidden;
}

.submit-btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.6s ease, height 0.6s ease;
}

.submit-btn:hover::before {
  width: 300px;
  height: 300px;
}

.submit-btn:hover {
  background-color: #3a62cc;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(74, 123, 255, 0.2);
}

.submit-btn:active {
  transform: translateY(0);
}

.alternative-contact {
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  color: var(--placeholder-color);
  transition: color 0.3s ease;
}

.alternative-contact:hover {
  color: var(--text-color);
}

.email-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.email-link:hover {
  color: #3a62cc;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
    gap: 0;
  }

  .contact-form {
    padding: 30px 20px;
  }
}

/* Custom styles for react-toastify */
.Toastify__toast {
  border-radius: 12px;
}

.Toastify__toast--success {
  background-color: var(--success-color);
}

.Toastify__toast--error {
  background-color: var(--error-color);
}

/* Cursor effects */
input, textarea, .submit-btn {
  cursor: pointer;
}

input:hover, textarea:hover {
  cursor: text;
}

.submit-btn:hover {
  cursor: pointer;
}

/* Accessibility focus styles */
input:focus, textarea:focus, .submit-btn:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}