.background {
    background: #add8e6;
    padding: 4rem 0;
}

@media screen and (max-height: 500px) {
    .background {
        height: auto;
        min-height: 500px;
    }
}

.link {
    border-radius: 50px;
    background: #8AC9EB;
    white-space: nowrap;
    padding: 12px 30px;
    color: #010606;
    font-size: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.link:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.gitLink {
    border-radius: 50px;
    background: #8AC9EB;
    white-space: nowrap;
    padding: 12px 30px;
    color: #010606;
    font-size: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-right: 10px;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.gitLink:hover {
    transition: all 0.2s ease-in-out;
    background: #5A84A4;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.image-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 10px;
    background: white;
    border-radius: 50%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.image-container:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
}

.imgTag {
    width: 100%;
    height: auto;
    border-radius: 50%;
    display: block;
}

.skillImgTag{
    width: 100%;
    height: auto;
    display: block;
    padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
    .background {
        padding: 2rem 0;
    }

    .image-container {
        max-width: 200px;
        margin-bottom: 2rem;
    }

    .link, .gitLink {
        font-size: 16px;
        padding: 10px 24px;
    }
}

/* Additional styles for better responsiveness */
@media screen and (max-width: 480px) {
    .Heading {
        font-size: 24px;
    }

    .Subtitle {
        font-size: 16px;
    }
}

/* Enhance the overall layout */
.InfoWrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 24px;
}

.InfoRow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.Column1, .Column2 {
    flex: 1;
    padding: 0 15px;
}

@media screen and (max-width: 768px) {
    .InfoRow {
        flex-direction: column-reverse;
    }

    .Column1, .Column2 {
        width: 100%;
        margin-bottom: 2rem;
    }
}

/* Add some animation */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

.TextWrapper, .ImgWrap {
    animation: fadeIn 0.8s ease-out;
}

.about-list {
    list-style-type: none;
    padding-left: 0;
    margin-top: 1rem;
}

.about-list li {
    margin-bottom: 0.75rem;
}