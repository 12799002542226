.ServicesContainer {
    height: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
}



.ServicesWrapper {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /*transition: transform 0.5s ease-in-out;*/
    /*grid-template-columns: repeat(3, 1fr);*/
    align-items: center;
    justify-content: center;
    grid-gap: 16px;
    padding: 0 50px;
}

.animateCardMove {
    transition: transform 1s ease-in-out;
}
.slide-in {
    transform: translateX(0);
    transition: none;
}

.slide-out {
    transform: translateX(-400px);
}

.animateCardFade {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    transform: scale(0.9); /* Example for scaling effect */
    opacity: 0; /* Initially hidden */
}

.fade-in {
    opacity: 1; /* Fully visible */
    transform: scale(1); /* Reset any scale transform */
}

.fade-out {
    opacity: 0; /* Fully hidden */
    transform: scale(0.9); /* Example for shrinking effect on fade-out */
}


.hideCard{
    display: none;

}
.disabled{
    pointer-events: none;
}





@media screen and (max-width: 1050px) {
    .ServicesWrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .ServicesWrapper {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
}

.ServicesCard {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 300px;
    height: 325px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 375px) {
        height: 400px;
    }
}

.ServicesCard:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.ServicesIcon {
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
}

.ServicesH1 {
    font-size: 2.5rem;
    color: #fff;
}

@media screen and (max-width: 480px) {
    .ServicesH1 {
        font-size: 2rem;
    }
}

.ServicesH2 {
    color: black;
    font-size: 1rem;
    margin-bottom: 10px;
}

.ServicesP {
    color: black;
    font-size: 1rem;
    text-align: center;
}

.SocialIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    gap: 10px;
    color: black;
    transition: 0.3s ease-out;
}

/*.SocialIcons {*/
/*    position: absolute;*/
/*    bottom: 10px;*/
/*    right: 10px;*/
/*    display: flex;*/
/*    gap: 10px;*/
/*}*/




.SocialIconLink {
    color: black;
    font-size: 24px;
    transition: 0.3s ease-out;
}

.SocialIconLink:hover {
    transform: scale(1.3);
}

.TitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;
    margin-top: 64px;
}

.ProjectSlider{
    display: flex;
    margin-bottom: 64px;
}


.arrow-top-left {
    transform: rotate(-45deg); /* Changed from 45deg to -45deg */
    transform-origin: bottom left; /* Adjusted the transform-origin to mirror the arrow */
}
.arrow-bottom-left {
    transform: rotate(45deg); /* Changed from -45deg to 45deg */
    transform-origin: top left; /* Adjusted the transform-origin */
}
/* From Uiverse.io by Nawsome */
.arrow {
    cursor: pointer;
    height: 60px;
    position: relative;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.1s;
    width: 50px;
}

.arrowLeft{
    left: 50px;
}

.arrow-top, .arrow-bottom {
    background-color: #666;
    height: 4px;
    left: -5px;
    position: absolute;
    top: 50%;
    width: 100%;
}

.arrow-top:after, .arrow-bottom:after {
    background-color: #fff;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.15s;
}

.arrow-top-right {
    transform: rotate(45deg);
    transform-origin: bottom right;
}

.arrow-top:after {
    left: 100%;
    right: 0;
    transition-delay: 0s;
}

.arrow-bottom-right {
    transform: rotate(-45deg);
    transform-origin: top right;
}

.arrow-bottom:after {
    left: 0;
    right: 100%;
    transition-delay: 0.15s;
}

.arrow:hover .arrow-top:after {
    left: 0;
    transition-delay: 0.15s;
}

.arrow:hover .arrow-bottom:after {
    right: 0;
    transition-delay: 0s;
}

.arrow:active {
    transform: translateX(-50%) translateY(-50%) scale(0.9);
}